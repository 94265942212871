import http from '@/utils/request/http'

// 随机音乐人
export const randCreator = (params = {}) => {
    return http.get('/api/randCreator', params)
}
//新增定制信息
export const addCustom = (params={})=>{
	return http.post('/api/addCustom', params)
}
//获取定制音乐记录
export const customLog = (params={})=>{
    return http.get('/api/customLog',params)
}
// 定制已完成音乐列表
export const customMusicList = (params={})=>{
    return http.get('/api/customMusicList',params)
}